/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React, { useCallback, useRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Button } from 'semantic-ui-react';
import ActionsStep from './ActionsStep';
import styles from './Card.module.scss';
import NameEdit from './NameEdit';
import * as MediaPreviewType from '../../constants/MediaPreviewType';
import DefaultMediaPreviewContainer from '../../containers/MediaPreviewContainer.js';
import { usePopup } from '../../lib/popup';
import ContentPreview from '../ContentPreview/ContentPreview.js';

const getHeightOfIframe = (mediaPreviewType: number) => {
  if (mediaPreviewType === MediaPreviewType.EmbedYoutubeShort) {
    return 450;
  }
  return 136;
};

function Card({
  id,
  index,
  name,
  description,
  boardId,
  listId,
  projectId,
  isPersisted,
  allProjectsToLists,
  allBoardMemberships,
  canEdit,
  onUpdate,
  onMove,
  onTransfer,
  onDelete,
  onBoardFetch,
  ogImage,
  ogTitle,
  ogDescription,
  mediaLinkUrl,
  openMediaPreviewModal,
  backgroundColor,
  mediaPreviewUrl,
  scheduledToBeVisible,
  MediaPreviewContainer = DefaultMediaPreviewContainer,
}: {
  ogImage?: string;
  ogTitle?: string;
  ogDescription?: string;
  id: string;
  index: number;
  name: string;
  description?: string;
  boardId: string;
  listId: string;
  projectId: string;
  isPersisted: boolean;
  allProjectsToLists: readonly any[];
  allBoardMemberships: readonly any[];
  /* eslint-enable react/forbid-prop-types */
  canEdit: boolean;
  onUpdate: any;
  onMove: any;
  onTransfer: any;
  onDelete: any;
  onBoardFetch: any;
  embedUrl?: string;
  mediaUrl?: string;
  mediaPreviewUrl?: string;
  openMediaPreviewModal?: () => void;
  backgroundColor?: string;
  mediaLinkUrl?: string;
  scheduledToBeVisible: boolean;
  MediaPreviewContainer?: any;
}) {
  const nameEdit = useRef(null);

  const handleNameUpdate = useCallback(
    (newName) => {
      onUpdate({
        name: newName,
      });
    },
    [onUpdate],
  );

  const handleNameEdit = useCallback(() => {
    nameEdit.current.open();
  }, []);

  const ActionsPopup = usePopup(ActionsStep);

  const contentNode = (
    <>
      <div className={classNames(styles.name, 'text-lg text-blue100 font-medium pb-4')}>{name}</div>
      <MediaPreviewContainer
        url={mediaPreviewUrl}
        ogTitle={ogTitle}
        ogDescription={ogDescription}
        ogImage={ogImage}
        getHeightOfIframe={getHeightOfIframe}
        onClick={openMediaPreviewModal}
        mediaLinkUrl={mediaLinkUrl}
      />
      <div className="mt-3" />
      <div className={styles.details}>{description && <ContentPreview value={description} />}</div>
    </>
  );

  return (
    <Draggable draggableId={`card:${id}`} index={index} isDragDisabled={!isPersisted || !canEdit}>
      {({ innerRef, draggableProps, dragHandleProps }) => (
        <div
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...draggableProps}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...dragHandleProps}
          ref={innerRef}
          className={classNames(styles.wrapper, 'mb-4 rounded-sm')}
          data-test-id="Card"
        >
          {/* @ts-ignore */}
          <NameEdit ref={nameEdit} defaultValue={name} onUpdate={handleNameUpdate}>
            <div
              className={styles.card}
              style={{
                backgroundColor,
              }}
            >
              {isPersisted ? (
                <>
                  <div className={styles.content}>{contentNode}</div>
                  {canEdit && (
                    <ActionsPopup
                      // @ts-ignore
                      card={{
                        boardId,
                        listId,
                        projectId,
                        id,
                      }}
                      projectsToLists={allProjectsToLists}
                      boardMemberships={allBoardMemberships}
                      onNameEdit={handleNameEdit}
                      onUpdate={onUpdate}
                      onMove={onMove}
                      onTransfer={onTransfer}
                      onDelete={onDelete}
                      onBoardFetch={onBoardFetch}
                    >
                      <Button
                        className={classNames(styles.actionsButton, styles.target, 'top-3 right-2')}
                      >
                        <img src="/assets/images/icon-dots-blue.svg" alt="" className="w-4 h-4" />
                      </Button>
                    </ActionsPopup>
                  )}
                  {scheduledToBeVisible && (
                    <div className="absolute top-1 right-1">
                      <img src="/assets/images/icon-clock.svg" alt="" className="w-4 h-4" />
                    </div>
                  )}
                </>
              ) : (
                <span className={styles.content}>{contentNode}</span>
              )}
            </div>
          </NameEdit>
        </div>
      )}
    </Draggable>
  );
}

export default React.memo(Card);

import classNames from 'classnames';
import Embed from '../Embed/Embed';

function MediaPreviewEmbed({
  url,
  getHeightOfIframe,
  testId = 'MediaPreviewEmbed',
}: {
  url: string;
  getHeightOfIframe?: (mediaPreviewType: number) => number;
  testId?: string;
}) {
  const heightOfIframe = getHeightOfIframe ? getHeightOfIframe(0) : undefined;
  return (
    <div
      className={classNames(
        'flex h-full max-h-full contain-content',
        testId === 'MediaPreviewYoutubeShort' ? 'w-auto' : 'w-full',
      )}
      data-testid={testId}
    >
      <Embed url={url} heightOfIframe={heightOfIframe} />
    </div>
  );
}

export default MediaPreviewEmbed;
